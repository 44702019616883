import Service from '../Service';

const resource = "discartdamagecultive/"

export default {
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID },
        });
    },

    /* list(TypeCultive, RcfID, requestID) {
        return Service.post(resource + "list", {}, {
            params: { TypeCultive, RcfID, requestID },
        });
    }, */

    save(ddc, requestID) {
        return Service.post(resource + 'save', ddc, {
            params: { requestID }
        });
    },
}