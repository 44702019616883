<template>
	<div>
		<s-crud
			add
			@save="save($event)"
			edit
			remove
			:filter="filter"
			:config="config"
			title="Descarte daños cultivo"
			height="auto"
			search-input
		>
			<template v-slot:filter>
				<v-container>
					<v-row justify="center">
						<v-col lg="4"
							><s-select-definition
								clearable
								v-model="filter.TypeDamage"
								:def="1265"
								label="Tipo Daño"
							></s-select-definition>
						</v-col>
						<v-col lg="3">
							<s-select-definition
								v-model="filter.TypeCultive"
								:def="1173"
								label="Tipo Cultivo"
							></s-select-definition>
						</v-col>
					</v-row>
				</v-container>
			</template>
			<template scope="props">
				<v-col>
					<v-row justify="center">
						<v-col class="s-col-form" cols="3">
							<s-select-definition
								v-model="props.item.TypeDamage"
								:def="1265"
								label="Tipo Daño"
							></s-select-definition>
						</v-col>
						<v-col class="s-col-form" cols="3">
							<s-select-definition
								:def="1173"
								v-model="props.item.TypeCultive"
								label="Cultivo"
							>
							</s-select-definition>
						</v-col>
					</v-row>
					<v-row justify="center">
						<v-col class="s-col-form" cols="3">
							<s-text
								v-model="props.item.DdcDescription"
								label="Descripción"
							>
							</s-text>
						</v-col>
						<v-col class="s-col-form" cols="3">
							<s-text
								v-model="props.item.DdcAbbreviation"
								label="Abreviación"
							>
							</s-text>
						</v-col>
						<!-- <v-col class="s-col-form" cols="3">
							<s-select-definition
								v-model="props.item.DdcStatus"
								:def="1285"
								label="Estado"
							>
							</s-select-definition>
						</v-col> -->
					</v-row>
				</v-col>
			</template>

			<template v-slot:DdcStatus="{ row }">
				<v-chip x-small :color="row.DdcStatus == 1 ? 'success' : 'error'">
					{{ row.DdcStatusText }}
				</v-chip>
			</template>
		</s-crud>
	</div>
</template>

<script>
	import _sPrfDiscartDamageCultiveService from "../../../services/FreshProduction/PrfDiscartDamageCultiveService";

	export default {
		data() {
			return {
				filter: {},
				config: {
					model: {
						DdcID: "ID",
						DdcStatus: "number",
						DdcStatusText: "string",
					},
					service: _sPrfDiscartDamageCultiveService,
					headers: [
						{ text: "ID", value: "DdcID" },

						{ text: "Descripción", value: "DdcDescription" },
						{ text: "Abreviación", value: "DdcAbbreviation", width: 100 },
						{ text: "Tipo Daño", value: "TypeDamageName" },
						{ text: "Tipo Cultivo", value: "TypeCultiveText" },
						{ text: "Estado", value: "DdcStatus", width: 100 },
					],
				},
			};
		},

		methods: {
			save(item) {
				if(item.DdcDescription.length == 0){
					this.$fun.alert("Ingrese una descripcion","warning")
					return;
				}

				if(item.TypeCultive == null ){
					this.$fun.alert("Seleccione un cultivo","warning")
					return;
				}

				if(item.TypeDamage == null ){
					this.$fun.alert("Seleccione un Tipo de defecto","warning")
					return;
				}

				console.log(item);
				item.save();
			},
		},
	};
</script>
